import { tint } from "polished"
import styled from "styled-components"
import React, { forwardRef } from "react"
import { ButtonTheme, ThemeInterface, Variants } from "../../styled/interfaces"
import {
  mediaHeightSDown,
  mediaQuery,
  mediaXSmallDown,
} from "../../styled/mediaQueries"
import { getVariantStyles } from "../../styled/utils"

import { ReactComponent as ChevronRight } from "../../images/icons/chevron-right.svg"
import { ReactComponent as ChevronLeft } from "../../images/icons/chevron-left.svg"

const ArrowRightStyled = styled(ChevronRight)<{ selected?: boolean }>`
  width: calc(var(--arrow-spacing) * 4 * var(--button-size-factor));
  min-width: calc(var(--arrow-spacing) * 4 * var(--button-size-factor));
  height: calc(var(--arrow-spacing) * 4 * var(--button-size-factor));
  margin-left: var(--arrow-spacing);
  display: block;

  ${({ selected }) =>
    selected
      ? `
    display: none;
  `
      : ""}
`

const ArrowLeftStyled = styled(ChevronLeft)<{ selected?: boolean }>`
  width: calc(var(--arrow-spacing) * 4 * var(--button-size-factor));
  height: calc(var(--arrow-spacing) * 4 * var(--button-size-factor));
  margin-right: var(--arrow-spacing);
  display: none;

  ${({ selected }) =>
    selected
      ? `
    display: block;
  `
      : ""}
`

const createButtonStyles = (buttonStyles: ButtonTheme, active?: boolean) => `
--arrow-spacing: ${buttonStyles.spacing || "0.625rem"};
user-select: none;
line-height: 1.15;
display: flex;
align-items: center;
border-color: ${buttonStyles.borderColor};
background: ${buttonStyles.background};
padding: ${buttonStyles.padding};
font-size: calc(${buttonStyles.fontSize} * var(--size-factor));
border-radius: ${buttonStyles.borderRadius};
border-style: ${buttonStyles.borderStyle};
border-width: ${buttonStyles.borderWidth};
transition: all 0.2s ease-in-out;
cursor: ${buttonStyles.cursor};
font-weight: ${buttonStyles.fontWeight};
color: ${buttonStyles.color};
text-decoration: none;

${
  buttonStyles.size
    ? `
  min-width: calc(${buttonStyles.size} * var(--button-size-factor));
  min-height: calc(${buttonStyles.size} * var(--button-size-factor));
`
    : ""
}

&>svg {
  transition: fill 0.2s ease-in-out;
  fill: ${buttonStyles.color};
  ${
    buttonStyles.size
      ? `
    width: calc(${buttonStyles.size} * var(--button-size-factor));
    height: calc(${buttonStyles.size} * var(--button-size-factor));
  `
      : ""
  }
}

&:hover:not(:disabled) {
  border-color: ${buttonStyles.borderColorHover};
  background: ${buttonStyles.backgroundHover};
  color: ${buttonStyles.colorHover};
  &>svg {
    fill: ${buttonStyles.colorHover};
  }
}

${
  buttonStyles.contentCenter
    ? `
  display: flex;
  justify-content:center;
  align-items: center;
`
    : ""
}

${
  buttonStyles.paddingMobile
    ? `
    ${mediaQuery(`${mediaXSmallDown}, ${mediaHeightSDown}`)} {
      padding: ${buttonStyles.paddingMobile};
    }
`
    : ""
}
${
  buttonStyles.borderRadiusMobile
    ? `
    ${mediaQuery(`${mediaXSmallDown}, ${mediaHeightSDown}`)} {
      border-radius: ${buttonStyles.borderRadiusMobile};
    }
`
    : ""
}

${
  buttonStyles.onlyBottomBorder
    ? `
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
`
    : ""
}

${
  active
    ? `
border-color: ${buttonStyles.borderColorHover};
background: ${buttonStyles.backgroundHover};
color: ${buttonStyles.colorHover};
&>svg {
  fill: ${buttonStyles.colorHover};
}
`
    : ""
}

&:disabled {
  cursor: auto;
  color: ${tint(0.5, buttonStyles.color)};

  &>svg {
    fill: ${tint(0.5, buttonStyles.color)};
  }
}
`

export const ButtonStyled = styled.button(
  (props: {
    theme: ThemeInterface
    onClick?: () => void
    disabled?: boolean
    active?: boolean
    variants?: Variants
  }) => {
    const buttonStyles = getVariantStyles<ButtonTheme>(
      props.theme.button,
      props.variants
    )

    return createButtonStyles(buttonStyles, props.active)
  }
)

const ButtonLinkStyled = styled.a(
  (props: {
    theme: ThemeInterface
    onClick?: () => void
    disabled?: boolean
    active?: boolean
    variants?: Variants
  }) => {
    const buttonStyles = getVariantStyles<ButtonTheme>(
      props.theme.button,
      props.variants
    )

    return createButtonStyles(buttonStyles, props.active)
  }
)

interface ButtonProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any
  active?: boolean
  variants?: Variants
  className?: string
  type?: "button" | "link"
  href?: string
  target?: string
  rel?: string
  download?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
}

// eslint-disable-next-line react/display-name
const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { children, variants, active, type, href, target, rel, download, ...rest },
    ref
  ) => {
    const isSubMenu =
      typeof variants === "string"
        ? variants.includes("submenu")
        : Object.keys(variants || {}).includes("submenu")

    const isLink = type === "link"

    return !isLink ? (
      <ButtonStyled variants={variants} active={active} ref={ref} {...rest}>
        {isSubMenu && <ArrowLeftStyled selected={active} />}
        {children}
        {isSubMenu && <ArrowRightStyled selected={active} />}
      </ButtonStyled>
    ) : (
      <ButtonLinkStyled
        variants={variants}
        active={active}
        href={href}
        target={target}
        rel={rel}
        download={download}
        {...rest}
      >
        {children}
      </ButtonLinkStyled>
    )
  }
)

export default Button
