import React, { useContext } from "react"
import { ErrorBoundary } from "react-error-boundary"
import ErrorFallback from "./components/common/ErrorFallback/ErrorFallback"
import SnackbarsRenderer from "./components/SnackbarsRenderer/SnackbarsRenderer"
import ThreeDStream from "./components/ThreeDStream/ThreeDStream"
import Welcome from "./components/Welcome/Welcome"
import useSetupApp from "./hooks/useSetupApp"
import useTenantSettingsFetcher from "./hooks/useTenantSettingsFetcher"
import { GlobalStateContext } from "./store/globalState/GlobalStateProvider"
import {
  GlobalStateAction,
  GlobalStateActionType,
} from "./store/globalState/interfaces"
import ThreeDStateProvider from "./store/threeDState/ThreeDStateProvider"
import SnackbarStateProvider from "./store/snackbarState/SnackbarStateProvider"
import { getStartParameters } from "./utils/StartUrlParameters"
import useLogin from "./hooks/useLogin"
import StylesStateProvider from "./store/stylesState/StylesStateProvider"
import LanguageSelector from "./components/LanguageSelector/LanguageSelector"
import Background from "./components/common/Background"
import useApartmentInfoFetcher from "./hooks/useApartmentInfoFetcher"

export const createOnError = (
  dispatch: React.Dispatch<GlobalStateActionType>
): ((
  error: Error,
  info?: {
    componentStack: string
  }
) => void) => error =>
  dispatch({ type: GlobalStateAction.SET_APPLICATION_ERROR, data: error })

const App: React.FC = () => {
  const { globalState, dispatch } = useContext(GlobalStateContext)

  useSetupApp(dispatch, getStartParameters)
  useLogin(dispatch, globalState)
  useTenantSettingsFetcher(dispatch, globalState)
  useApartmentInfoFetcher()

  return Object.keys(globalState.tenantSettings).length ? (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={createOnError(dispatch)}
    >
      <Background
        background={globalState.tenantSettings.customerJourney2_mainImage}
      >
        <SnackbarStateProvider>
          {globalState.hasThreeDStarted ? (
            <ThreeDStateProvider>
              <StylesStateProvider>
                <ThreeDStream />
              </StylesStateProvider>
            </ThreeDStateProvider>
          ) : (
            <>
              <Welcome />
              <LanguageSelector />
            </>
          )}
          <SnackbarsRenderer />
        </SnackbarStateProvider>
      </Background>
    </ErrorBoundary>
  ) : null
}

export default App
