import React, { useContext } from "react"
import styled from "styled-components"
import { GlobalStateContext } from "../../store/globalState/GlobalStateProvider"
import { LogoTheme, ThemeInterface, Variants } from "../../styled/interfaces"
import {
  mediaHeightLDown,
  mediaHeightLUp,
  mediaMediumDown,
  mediaMediumUp,
  mediaQuery,
} from "../../styled/mediaQueries"
import { getVariantStyles } from "../../styled/utils"

export const LogoImg = styled.img(
  (props: { theme: ThemeInterface; variants?: Variants }) => {
    const logoStyles = getVariantStyles<LogoTheme>(
      props.theme.logo,
      props.variants
    )

    return `
      margin-bottom: 0.625rem;
      margin-top: 1.25rem;
      z-index: 0;

      ${logoStyles.width ? `width: ${logoStyles.width};` : ""}
      ${logoStyles.position ? `position: ${logoStyles.position};` : ""}
      ${logoStyles.left ? `left: ${logoStyles.left};` : ""}
      ${logoStyles.bottom ? `bottom: ${logoStyles.bottom};` : ""}
    
      ${
        logoStyles.hideOnDesktop
          ? `
        display: none;
        ${mediaQuery(`${mediaMediumUp} and ${mediaHeightLUp}`)} {
          display: block;
        }
      `
          : ""
      }
      ${
        logoStyles.hideOnMobile
          ? `
        display: none;
        ${mediaQuery(`${mediaMediumDown} and ${mediaHeightLDown}`)} {
          display: block;
        }
    `
          : ""
      }
    `
  }
)
interface LogoProps {
  [x: string]: any // eslint-disable-line
}

// eslint-disable-next-line
const Logo: React.FC<LogoProps> = props => {
  const { globalState } = useContext(GlobalStateContext)

  return (
    <LogoImg
      src={
        globalState.tenantSettings.logoUrls?.organisationLogoLightUrl ||
        "GBVerticalBlack_256.png"
      }
      alt="gbuilder"
      {...props}
    />
  )
}

export default Logo
