import React from "react"
import styled, { keyframes } from "styled-components"

const spinningAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const CircularSpinnerStyled = styled.div<{ size: number }>`
  display: inline-block;
  position: relative;
  width: calc(80px * ${({ size }) => size});
  height: calc(80px * ${({ size }) => size});

  & > div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: calc(64px * ${({ size }) => size});
    height: calc(64px * ${({ size }) => size});
    margin: calc(8px * ${({ size }) => size});
    border: calc(8px * ${({ size }) => size}) solid #fff;
    border-radius: 50%;
    animation: ${spinningAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--colorMain) transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
`

const CircularSpinner: React.FC<{ size: number }> = ({ size }) => {
  return (
    <CircularSpinnerStyled size={size}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </CircularSpinnerStyled>
  )
}

export default CircularSpinner
