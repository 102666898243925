import React, { useContext, useEffect, useMemo, useRef, useState } from "react"

import Spinner from "../Spinner/Spinner"
import Controls from "./Controls"
import { useThreeDStreaming } from "./threeDStreaming"
import H3 from "../common/texts/H3"
import { ThreeDStateContext } from "../../store/threeDState/ThreeDStateProvider"
import Button from "../common/Button"
import { t } from "../../utils"
import { ThreeDStateAction } from "../../store/threeDState/interfaces"
import Paper from "../common/Paper/Paper"
import { H6, P } from "../common/texts"

import ThemesAndMaterials from "../StylesAndMaterials/StylesAndMaterials"
import { useOutsideClickListener } from "../../hooks/useOutsideClickListener"
import LayoutButton from "./LayoutButton"
import { GlobalStateContext } from "../../store/globalState/GlobalStateProvider"
import { GlobalStateAction } from "../../store/globalState/interfaces"
import CameraButton from "./CameraButton"
import LanguageSelector from "../LanguageSelector/LanguageSelector"
import Disclaimer from "../Disclaimer/Disclaimer"
import HubspotContactForm from "../HubSpotComponents/HubSpotContactForm"
import { Instructions, LoaderContainer, StyleIconStyled, ThreeDContainer, TopButtonsRowStyled, Video } from "./threeDStream.styles"
import UserNote from "../common/UserNote/UserNote"



const usePlayVideo = (
  videoElement: HTMLVideoElement | null,
  videoSrc: MediaStream | null
) => {
  useEffect(() => {
    if (videoElement && videoSrc) {
      videoElement.srcObject = videoSrc
      videoElement.play()
    }
  }, [videoSrc, videoElement])
}

interface ThreeDStreamProps {
  children?: React.ReactNode
}

const ThreeDStream: React.FC<ThreeDStreamProps> = ({ children }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const { globalState, dispatch: globalStateDispatch } =
    useContext(GlobalStateContext)
  const { threeDState, dispatch } = useContext(ThreeDStateContext)
  const {
    videoSrc,
    move,
    apartmentReady,
    loadingMessage,
    errorMessage,
    resetCamera,
    selectTheme,
    previewMaterial,
    showControls,
    floors,
    selectFloor,
    takePicture,
  } = useThreeDStreaming(videoRef, () => {
    dispatch({
      type: ThreeDStateAction.RESET_STORE,
    })
    globalStateDispatch({
      type: GlobalStateAction.SET_HAS_THREE_D_STARTED_ACTION,
      data: false,
    })
  })

  const SHOW_HUBSPOT_FORM = Boolean(
    process.env.REACT_APP_THREE_D_SHOWHUBSPOTFORM || false
  )

  const HUBSPOTFORM_TIMEOUT = Number(
    process.env.REACT_APP_THREE_D_HUBSPOTFORM_TIMEOUT || null
  )

  const startHubSpotTimer = () => {
    if (!globalState.token ) {
      const hubspotTimerID = setTimeout(showHubSpotForm, HUBSPOTFORM_TIMEOUT);
    }
  }

  const showHubSpotForm = () => {
    dispatch({
      type: ThreeDStateAction.SET_OPEN_HUBSPOTFORM,
      data: true,
    })
  }

  /* Commented out the activation of hubspot form, as this was done for demo only. Will be taken into use at some point,
     after implementing the form modal and sending its data to hubspot API instead of using the embedded form 
     from hubspot url */
  // useEffect(()=> {
  //  if (SHOW_HUBSPOT_FORM && threeDState.instructionsDismissed) {
  //    startHubSpotTimer();
  //  }
  // }, [threeDState.instructionsDismissed])

  usePlayVideo(videoRef.current, videoSrc)

  const themesAndMaterialsClickListenerRef = useRef<HTMLDivElement | null>(null)

  useOutsideClickListener(themesAndMaterialsClickListenerRef, () => {
    if (threeDState.themesAndMaterialsOpen)
      dispatch({
        type: ThreeDStateAction.TOGGLE_THEMES_AND_MATERIALS_OPEN,
      })
  })

  const showThemes =
    !globalState.tenantSettings.gb4dUxModes ||
    globalState.tenantSettings.gb4dUxModes.some(mode => mode.showThemes)
  const showMaterials =
    !globalState.tenantSettings.gb4dUxModes ||
    globalState.tenantSettings.gb4dUxModes.some(mode => mode.showMaterials)

  const themesAndMaterialsEnabled =
    showThemes || (showMaterials && globalState.token)

  const styledAndMaterialsButtonRef = useRef<HTMLButtonElement | null>(null)

  // useMemo here because the component listens to a lot of state/context changes
  return useMemo(
    () => (
      <ThreeDContainer>
        <Video
          autoPlay
          playsInline
          apartmentReady={apartmentReady}
          ref={videoRef}
        />
        {!threeDState.themesAndMaterialsOpen && <LanguageSelector isMobile />}
        <LanguageSelector />
        {apartmentReady && (
          <>
            {move && (
              <Controls onHold={move} showControls={showControls}>
                <LayoutButton
                  variants="controlButton rounded"
                  resetCamera={resetCamera}
                  floors={floors}
                  selectFloor={selectFloor}
                  showFloors={!showControls}
                />
                <CameraButton
                  onClick={takePicture}
                  inProgress={threeDState.downloadingPicture}
                  showFloors={!showControls && floors > 1}
                  showControls={showControls}
                />
              </Controls>
            )}
            {showControls && themesAndMaterialsEnabled && (
              <TopButtonsRowStyled>
                <div
                  style={{ position: "relative", justifySelf: "start" }}
                  ref={themesAndMaterialsClickListenerRef}
                >
                  <Button
                    variants={{
                      rounded: true,
                    }}
                    active={threeDState.themesAndMaterialsOpen}
                    onClick={() =>
                      dispatch({
                        type: ThreeDStateAction.TOGGLE_THEMES_AND_MATERIALS_OPEN,
                      })
                    }
                    ref={styledAndMaterialsButtonRef}
                  >
                    <StyleIconStyled
                      style={{
                        marginRight:
                          "calc(0.625rem * var(--button-size-factor))",
                      }}
                    />
                    {t("theming.selections")}
                  </Button>
                  {threeDState.themesAndMaterialsOpen && (
                    <ThemesAndMaterials
                      anchorRef={styledAndMaterialsButtonRef}
                      close={() =>
                        dispatch({
                          type: ThreeDStateAction.TOGGLE_THEMES_AND_MATERIALS_OPEN,
                        })
                      }
                      selectTheme={selectTheme}
                      previewMaterial={previewMaterial}
                    />
                  )}
                </div>
                {/* <Button variants="controlButton rounded" disabled>
                <SettingsIconStyled />
              </Button> */}
              </TopButtonsRowStyled>
            )}
          </>
        )}
        {(!apartmentReady || !threeDState.instructionsDismissed) && (
          <Paper
            variants="contentCenter mobileFullScreen transparent"
            style={{ zIndex: 100 }}
          >
            <Instructions>
              <H3 variants="withMarginBottom" style={{ alignSelf: "center" }}>
                {t("instructions.title")}
              </H3>
              <P variants="xs withMarginBottom">
                {t("instructions.instructionsParagraph1")}
              </P>
              <P variants="xs withMarginBottom">
                {t("instructions.instructionsParagraph2")}
              </P>
            </Instructions>
            <H6 variants="withMarginBottom" style={{ alignSelf: "center" }}>
              {globalState.apartmentInfo.name}
            </H6>
            <LoaderContainer>
              {loadingMessage && !errorMessage && (
                <P
                  variants="xs"
                  style={{ maxWidth: "40rem", textAlign: "center" }}
                >
                  {loadingMessage}
                </P>
              )}
              {!apartmentReady && !errorMessage &&
                <Spinner variants="s" />
              }
              {errorMessage &&
                 <UserNote variant="filled" severity="error" alertText={errorMessage} />
              }
              {!errorMessage &&
                <Button
                  variants="large"
                  onClick={() => {
                      window.dispatchEvent(new Event('instructionsClosed'))
                      dispatch({ type: ThreeDStateAction.DISMISS_INSTRUCTIONS })
                    }
                  }
                  disabled={!!(!apartmentReady || loadingMessage)}
                >
                  {t("instructions.enterButtonText")}
                </Button>
              }
            </LoaderContainer>
          </Paper>
        )}
        <Disclaimer />
        {children}
        {threeDState.openHubSpotForm && (
          <Paper
            variants="contentCenter mobileFullScreen transparent"
            style={{ zIndex: 100 }}
          >
          <Instructions>
            <H3 variants="withMarginBottom" style={{ alignSelf: "center" }}>
              {t("hubspotForm.title")}
            </H3>
            <P variants="xs withMarginBottom">
              {t("hubspotForm.instructionsParagraph1")}
            </P>
          </Instructions>
          <HubspotContactForm />
          <Button onClick={() => {
            dispatch({
              type: ThreeDStateAction.SET_OPEN_HUBSPOTFORM,
              data: false,
            })
          }
            }>{t("hubspotForm.closeButton")}</Button>
          </Paper>
        )}
      </ThreeDContainer>
    ),
    [
      threeDState.downloadingPicture,
      move,
      apartmentReady,
      errorMessage,
      threeDState.instructionsDismissed,
      threeDState.themesAndMaterialsOpen,
      loadingMessage,
      children,
      dispatch,
      resetCamera,
      selectTheme,
      previewMaterial,
      showControls,
      floors,
      selectFloor,
      themesAndMaterialsEnabled,
      takePicture,
      threeDState.openHubSpotForm,
    ]
  )
}

export default ThreeDStream
