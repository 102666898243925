export const Languages = [
  // {
  //   code: 'en-US',
  //   title: 'English (US)',
  // },
  {
    code: "en-GB",
    title: "English (GB)",
  },
  {
    code: "fi-FI",
    title: "Finnish",
  },
  {
    code: 'ru-RU',
    title: 'Russian',
  },
  {
    code: 'de-DE',
    title: 'German',
  },
  {
    code: 'et-EE',
    title: 'Estonian',
  },
  {
    code: 'nb-NO',
    title: 'Norwegian',
  },
  // {
  //   code: 'es-ES',
  //   title: 'Spanish',
  // },
  {
    code: 'cs-CZ',
    title: 'Czech',
  },
  {
    code: 'sk-SK',
    title: 'Slovak',
  },
  {
    code: 'sv-SE',
    title: 'Swedish',
  },
  // {
  //   code: 'lt-LT',
  //   title: 'Lithuanian',
  // },
  // {
  //   code: 'fr-FR',
  //   title: 'French',
  // },
  {
    code: 'nl-NL',
    title: 'Dutch',
  }
]
