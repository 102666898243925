import React, { useContext } from "react"
import styled from "styled-components"
import { DialogStateContext } from "../../../store/dialogState/DialogStateProvider"
import { DialogStateAction } from "../../../store/dialogState/interfaces"
import { t } from "../../../utils"
import Button from "../Button"
import Paper from "../Paper/Paper"
import { H5, P } from "../texts"
import { ReactComponent as OpenLink } from "../../../images/icons/open-link.svg"
import { ReactComponent as DownloadIcon } from "../../../images/icons/download.svg"

const DialogWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  overflow-x: none;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
`

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: calc(1.875rem * var(--button-size-factor));
`

const DialogContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Dialog: React.FC = () => {
  const { dialogState, dispatch } = useContext(DialogStateContext)

  // TODO link should be link, download should be download
  return dialogState.show ? (
    <DialogWrapper>
      <Paper>
        <DialogContentWrapper>
          {dialogState.title && (
            <H5 variants="withMarginBottom">{dialogState.title}</H5>
          )}
          {dialogState.text && (
            <P variants="withMarginBottom">{dialogState.text}</P>
          )}
          {dialogState.link && (
            <Button href={dialogState.link.href} type="link" variants="small">
              {t("open")}&nbsp;
              <OpenLink />
            </Button>
          )}
        </DialogContentWrapper>
        <ButtonRow>
          <Button
            variants="small"
            onClick={() => {
              dialogState.onCancel?.()
              dispatch({ type: DialogStateAction.CLOSE_DIALOG })
            }}
          >
            {dialogState.onCancelText || t("dialog.cancel")}
          </Button>
          {dialogState.file ? (
            <Button
              variants="small"
              type="link"
              href={`data:image/png;base64,${dialogState.file.file}`}
              download={dialogState.file.name}
              onClick={() => dispatch({ type: DialogStateAction.CLOSE_DIALOG })}
            >
              {t("download")}&nbsp;
              <DownloadIcon />
            </Button>
          ) : (
            <Button
              variants="small"
              onClick={() => {
                dialogState.onAccept?.()
                dispatch({ type: DialogStateAction.CLOSE_DIALOG })
              }}
            >
              {dialogState.onAcceptText || t("dialog.ok")}
            </Button>
          )}
        </ButtonRow>
      </Paper>
    </DialogWrapper>
  ) : null
}

export default Dialog
