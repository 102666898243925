import React, { useContext, useEffect } from "react"
import styled from "styled-components"
import { t } from "../../utils"
import Paper from "../common/Paper/Paper"
import Button from "../common/Button"
import StylesList from "./StylesList/StylesList"
import {
  mediaQuery,
  mediaMediumDown,
  mediaHeightLDown,
} from "../../styled/mediaQueries"
import { ReactComponent as CloseIcon } from "../../images/icons/close.svg"
import MaterialsList from "./MaterialsList/MaterialsList"
import { GlobalStateContext } from "../../store/globalState/GlobalStateProvider"
import { StylesStateAction, Tab } from "../../store/stylesState/interfaces"
import { StylesStateContext } from "../../store/stylesState/StylesStateProvider"

const PaperStyled = styled(Paper)`
  padding: 0 calc(1.25rem * var(--size-factor))
    calc(1.25rem * var(--size-factor)) calc(1.25rem * var(--size-factor));
  width: 40rem;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  grid-template-rows: auto minmax(0, 1fr) auto;
  max-width: 40rem;
  z-index: 20;
`

const TabsContainer = styled.nav`
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  width: 100%;
  padding: 0 calc(1.25rem * var(--size-factor));
  justify-self: start;
  align-self: stretch;

  ${mediaQuery(`${mediaMediumDown}, ${mediaHeightLDown}`)} {
    padding-right: 4rem;
    padding-top: calc(1.25rem * var(--size-factor));
  }

  & > :only-child {
    grid-column: 1 / -1;
    cursor: unset;
  }
`

const ButtonStyled = styled(Button)`
  display: none;
  position: absolute;
  right: calc(1.25rem * var(--size-factor));
  top: calc(1.25rem * var(--size-factor));

  ${mediaQuery(`${mediaMediumDown}, ${mediaHeightLDown}`)} {
    display: flex;
  }
`
interface StylesAndMaterialsProps {
  close: () => void
  selectTheme: (themeId: number) => void
  previewMaterial: (materialId: string, bundleId: number) => void
  anchorRef: React.RefObject<HTMLElement | null>
}

const StylesAndMaterials: React.FC<StylesAndMaterialsProps> = ({
  close,
  selectTheme,
  previewMaterial,
  anchorRef,
}) => {
  const { globalState } = useContext(GlobalStateContext)
  const { stylesState, dispatch: stylesStateDispatch } =
    useContext(StylesStateContext)

  const showThemes =
    !globalState.tenantSettings.gb4dUxModes ||
    globalState.tenantSettings.gb4dUxModes.some(mode => mode.showThemes)
  const showMaterials =
    !globalState.tenantSettings.gb4dUxModes ||
    globalState.tenantSettings.gb4dUxModes.some(mode => mode.showMaterials)

  useEffect(() => {
    if (stylesState.activeTab === null) {
      if (showThemes)
        stylesStateDispatch({
          type: StylesStateAction.SET_ACTIVE_TAB,
          data: Tab.STYLES,
        })
      else if (showMaterials && globalState.token)
        stylesStateDispatch({
          type: StylesStateAction.SET_ACTIVE_TAB,
          data: Tab.MATERIALS,
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <PaperStyled
      offset={{ top: 32, height: -32, width: -32 }}
      variants="noPadding transparent mobileFullScreen"
      anchorRef={anchorRef}
      onClose={close}
    >
      <TabsContainer>
        {showThemes && (
          <Button
            onClick={() =>
              stylesStateDispatch({
                type: StylesStateAction.SET_ACTIVE_TAB,
                data: Tab.STYLES,
              })
            }
            variants="tab"
            active={stylesState.activeTab === Tab.STYLES}
          >
            {t("theming.styles")}
          </Button>
        )}
        {globalState.token && showMaterials && (
          <Button
            style={{ gridColumn: 3 }}
            onClick={() =>
              stylesStateDispatch({
                type: StylesStateAction.SET_ACTIVE_TAB,
                data: Tab.MATERIALS,
              })
            }
            variants="tab"
            active={stylesState.activeTab === Tab.MATERIALS}
          >
            {t("theming.materials")}
          </Button>
        )}
      </TabsContainer>
      {stylesState.activeTab === Tab.STYLES && (
        <StylesList selectTheme={selectTheme} />
      )}
      {stylesState.activeTab === Tab.MATERIALS && <MaterialsList />}
      <ButtonStyled variants="icon" onClick={close}>
        <CloseIcon />
      </ButtonStyled>
    </PaperStyled>
  )
}

export default StylesAndMaterials
