import React, { useContext } from "react"
import styled from "styled-components"
import { Bundle, Room } from "../../../axios/instances/interfaces"
import useMaterialsFetcher from "../../../hooks/useMaterialsFetcher"
import useRoomsFetcher from "../../../hooks/useRoomsFetcher"
import { StylesStateAction } from "../../../store/stylesState/interfaces"
import { StylesStateContext } from "../../../store/stylesState/StylesStateProvider"
import { t } from "../../../utils"
import Button from "../../common/Button"
import { H5 } from "../../common/texts"
import Spinner from "../../Spinner/Spinner"
import RoomComponent from "./RoomComponent"
import SelectedBundle from "./SelectedBundle"

const MaterialListContainer = styled.div`
  padding: calc(1.25rem * var(--size-factor)) calc(1.25rem * var(--size-factor))
    0 calc(1.25rem * var(--size-factor));
  max-width: 100%;
  width: 100%;
  display: grid;
  grid-auto-rows: minmax(0, auto);
  align-content: start;
`

const CurrentView = styled.div`
  margin-top: calc(1.25rem * var(--size-factor));
  margin-bottom: calc(1.25rem * var(--size-factor));
`

const BreadcrumbsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

const getRoomBundles = (bundles: Bundle[], roomId: number): Bundle[] => {
  return bundles.filter(
    bundle => bundle.roomIds.includes(roomId) && bundle.showInRoomLevel
  )
}

const getCommonBundles = (bundles: Bundle[]): Bundle[] => {
  return bundles.filter(bundle => bundle.showInApartmentLevel)
}

const GENERAL_APPEARANCE = null
const NO_SELECTED_ROOM = -1

const MaterialsList: React.FC = () => {
  const { stylesState, dispatch } = useContext(StylesStateContext)
  const {
    openRoom,
    fetchingRooms,
    fetchingMaterials,
    rooms,
    bundles,
    selectedBundle,
  } = stylesState

  useMaterialsFetcher()
  useRoomsFetcher()

  const loading = fetchingRooms || fetchingMaterials

  const openRoomToBeDisplayed: Room | null =
    rooms?.find(room => room.id === openRoom) ?? null

  const currentView =
    selectedBundle?.name ??
    openRoomToBeDisplayed?.name ??
    t("theming.generalAppearance")

  return (
    <MaterialListContainer>
      {
        <>
          {openRoom !== NO_SELECTED_ROOM && (
            <>
              <BreadcrumbsRow>
                <Button
                  onClick={() => {
                    dispatch({
                      type: StylesStateAction.SET_OPEN_ROOM,
                      data: -1,
                    })
                    dispatch({
                      type: StylesStateAction.SELECT_BUNDLE,
                      data: null,
                    })
                  }
                  }
                  variants="submenu smallSubmenu"
                  active
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {t("theming.rooms")}
                </Button>
                {selectedBundle && (
                  <Button
                    onClick={() =>
                      dispatch({
                        type: StylesStateAction.SELECT_BUNDLE,
                        data: null,
                      })
                    }
                    variants="submenu smallSubmenu"
                    active
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {openRoomToBeDisplayed?.name ??
                      t("theming.generalAppearance")}
                  </Button>
                )}
              </BreadcrumbsRow>
              <CurrentView>
                <H5>{currentView}</H5>
              </CurrentView>
            </>
          )}
        </>
      }
      {loading ? (
        <Spinner
          variants="s"
          style={{
            alignSelf: "center",
            justifyContent: "center",
            display: "flex",
          }}
        />
      ) : (
        <div style={{overflow: "auto"}}>
          {openRoom !== NO_SELECTED_ROOM && bundles ? (
            <>
              {selectedBundle ? (
                <SelectedBundle bundle={selectedBundle} />
              ) : (
                <RoomComponent
                  room={openRoomToBeDisplayed}
                  bundles={
                    openRoomToBeDisplayed
                      ? getRoomBundles(bundles, openRoomToBeDisplayed.id)
                      : getCommonBundles(bundles)
                  }
                />
              )}
            </>
          ) : (
            <>
              <Button
                onClick={() =>
                  dispatch({
                    type: StylesStateAction.SET_OPEN_ROOM,
                    data: GENERAL_APPEARANCE,
                  })
                }
                variants={{
                  submenu: true,
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {t("theming.generalAppearance").toUpperCase()}
              </Button>
              {rooms?.map(room => (
                <Button
                  key={room.id}
                  onClick={() =>
                    dispatch({
                      type: StylesStateAction.SET_OPEN_ROOM,
                      data: room.id,
                    })
                  }
                  variants={{
                    submenu: true,
                  }}
                  style={{
                    display: "flex",
                    textAlign: "left"
                  }}
                >
                  {room.name.toUpperCase()}
                </Button>
              ))}
            </>
          )}
        </div>
      )}
    </MaterialListContainer>
  )
}

export default MaterialsList
