import React from "react"
import { PackageSet } from "../../../axios/instances/interfaces"
import Dropdown from "../../common/Dropdown/Dropdown"
import PackagesDisplay from "./PackagesDisplay"

interface PackageSetListElementProps {
  packageSet: PackageSet
  selected: boolean
  onClick: () => void
  selectPackage: (packageId: number) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
}

const PackageSetListElement: React.FC<PackageSetListElementProps> = ({
  packageSet,
  selected,
  selectPackage,
  onClick,
  ...rest
}) => {
  return (
    <Dropdown
      title={packageSet.name}
      open={selected}
      onClick={onClick}
      {...rest}
    >
      <PackagesDisplay
        packages={packageSet.packages}
        selectPackage={selectPackage}
      />
    </Dropdown>
  )
}

export default PackageSetListElement
