import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import useStylesFetcher from "../../../hooks/useStylesFetcher"
import { StylesStateContext } from "../../../store/stylesState/StylesStateProvider"
import Spinner from "../../Spinner/Spinner"
import PackageSetListElement from "./PackageSetListElement"

const StylesContainer = styled.div`
  padding: calc(1.25rem * var(--size-factor)) calc(1.25rem * var(--size-factor))
    0 calc(1.25rem * var(--size-factor));
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-content: start;
  overflow-y: auto;
`

interface StylesListProps {
  selectTheme: (themeId: number) => void
}

const StylesList: React.FC<StylesListProps> = ({ selectTheme }) => {
  const { stylesState } = useContext(StylesStateContext)

  const [selectedPackageSet, setSelectedPackageSet] = useState<null | number>(
    null
  )
  useStylesFetcher()

  useEffect(() => {
    if (stylesState.packageSets?.length && selectedPackageSet === null)
      setSelectedPackageSet(stylesState.packageSets?.[0]?.id || null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stylesState.packageSets])

  return (
    <StylesContainer>
      {stylesState.fetchingStyles ? (
        <Spinner variants="s" style={{ alignSelf: "center" }} />
      ) : (
        <>
          {stylesState.packageSets?.map(packageSet => {
            const isVisible =
              selectedPackageSet === null ||
              packageSet.id === selectedPackageSet

            return isVisible ? (
              <div style={{overflow: "auto"}}>
                <PackageSetListElement
                  onClick={() =>
                    setSelectedPackageSet(
                      selectedPackageSet === packageSet.id ? null : packageSet.id
                    )
                  }
                  packageSet={packageSet}
                  selectPackage={selectTheme}
                  selected={selectedPackageSet === packageSet.id}
                  key={packageSet.id}
                />
              </div>
            ) : null
          })}
        </>
      )}
    </StylesContainer>
  )
}

export default StylesList
