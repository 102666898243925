import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { ReactComponent as MoveUpIcon } from "../../images/icons/move-up.svg"
import { ReactComponent as MoveDownIcon } from "../../images/icons/move-down.svg"
import { ReactComponent as MoveLeftIcon } from "../../images/icons/move-left.svg"
import { ReactComponent as MoveRightIcon } from "../../images/icons/move-right.svg"
import { ReactComponent as RotateLeftIcon } from "../../images/icons/rot-left.svg"
import { ReactComponent as RotateRightIcon } from "../../images/icons/rot-right.svg"
import Button from "../common/Button"
import { MoveType } from "./threeDStreaming"
import { mediaPortrait, mediaQuery } from "../../styled/mediaQueries"

const ControlsContainer = styled.div`
  position: absolute;
  bottom: calc(var(--button-size-factor) * 1.875rem);
  right: calc(var(--button-size-factor) * 1.875rem);

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  ${mediaQuery(mediaPortrait)} {
    right: 50%;
    transform: translateX(50%);
    bottom: calc(var(--button-size-factor) * 1.875rem);
  }
`

const useHold: (
  onHold: (moveType: MoveType, stop?: boolean) => void
) => (type: MoveType) => void = onHold => {
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null)
  const [moveType, setMoveType] = useState<MoveType | null>(null)

  useEffect(() => {
    const stopHold = () => {
      if (intervalId) clearInterval(intervalId)
      setIntervalId(null)
      if (moveType) {
        setMoveType(null)
      }
    }

    window.addEventListener("mouseup", stopHold)
    window.addEventListener("touchend", stopHold)
    window.addEventListener("dragstart", stopHold)

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
        setIntervalId(null)
      }

      window.removeEventListener("mouseup", stopHold)
      window.removeEventListener("touchend", stopHold)
      window.removeEventListener("dragstart", stopHold)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intervalId])

  const startHold: (MoveType: MoveType) => void = MoveType => {
    setMoveType(MoveType)
    setIntervalId(setInterval(() => onHold(MoveType), 50))
  }

  return startHold
}

const createActions = (
  type: MoveType,
  onHold: (type: MoveType, stop?: boolean) => void
) => ({
  onMouseDown: () => onHold(type),
  onTouchStart: () => onHold(type),
})
interface ControlsProps {
  onHold: (type: MoveType, stop?: boolean) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any
  showControls: boolean
}

const Controls: React.FC<ControlsProps> = ({
  onHold,
  children,
  showControls,
}) => {
  const startHold = useHold(onHold)

  // ABOUT WEIRD ROTATE: https://stackoverflow.com/questions/40363916/svg-transform-rotate-by-90-180-or-270-degrees-not-working-on-circle-in-safari-i
  return (
    <ControlsContainer>
      {children}
      {showControls && (
        <>
          <Button
            variants="controlButton controlButton1"
            {...createActions(MoveType.ROTATE_LEFT, startHold)}
          >
            <RotateLeftIcon
              height="60px"
              width="60px"
            />
          </Button>
          <Button
            variants="controlButton controlButton2"
            {...createActions(MoveType.UP, startHold)}
          >
            <MoveUpIcon height="60px" width="60px" />
          </Button>
          <Button
            variants="controlButton controlButton3"
            {...createActions(MoveType.ROTATE_RIGHT, startHold)}
          >
            <RotateRightIcon
              height="60px"
              width="60px"
            />
          </Button>
          <Button
            variants="controlButton controlButton4"
            {...createActions(MoveType.LEFT, startHold)}
          >
            <MoveLeftIcon height="60px" width="60px" />
          </Button>
          <Button
            variants="controlButton controlButton5"
            {...createActions(MoveType.DOWN, startHold)}
          >
            <MoveDownIcon height="60px" width="60px" />
          </Button>
          <Button
            variants="controlButton controlButton6"
            {...createActions(MoveType.RIGHT, startHold)}
          >
            <MoveRightIcon height="60px" width="60px" />
          </Button>
        </>
      )}
    </ControlsContainer>
  )
}

export default Controls
