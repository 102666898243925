import styled from "styled-components"
import { ReactComponent as StyleIcon } from "../../images/icons/style.svg"
import {
  mediaHeightSDown,
  mediaMediumUp,
  mediaQuery,
} from "../../styled/mediaQueries"

export const ThreeDContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Video = styled.video<{ apartmentReady: boolean }>`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 100%;
  min-width: 100%;
  aspect-ratio: 16 / 9;
  display: ${({ apartmentReady }) => (apartmentReady ? "block" : "none")};
`

export const LoaderContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: calc(1rem * var(--margin-factor));
`

export const Instructions = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 28rem;
  max-width: calc(100% - 3.75rem);
  margin: calc(1rem * var(--margin-factor));

  ${mediaQuery(mediaMediumUp)} {
    width: 60rem;
  }
`

export const StyleIconStyled = styled(StyleIcon)`
  width: calc(60px * var(--button-size-factor));
  height: calc(60px * var(--button-size-factor));
`

export const TopButtonsRowStyled = styled.div`
  position: absolute;
  top: 0;
  width: calc(100% - (var(--button-size-factor) * 1.875rem * 2));
  margin-top: calc(var(--button-size-factor) * 1.875rem);

  display: grid;
  grid-template-columns: 1fr auto;

  & > :nth-child(3) {
    grid-column: 2;
    margin-top: 1.25rem;
  }

  ${mediaQuery(mediaHeightSDown)} {
    & > :nth-child(3) {
      margin-top: 0.625rem;
    }
  }
`