import i18n from "i18next"
import LngDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
import resources from "../locales/"

const instance = i18n
  .use(LngDetector)
  .use(initReactI18next)
  .init(
    {
      resources,
      debug: false,
      fallbackLng: "en",
      detection: {
        caches: ['sessionStorage']
      },
      load: "languageOnly",
      defaultNS: "main",
      interpolation: {
        escapeValue: false,
      },

      react: {
        wait: false,
        nsMode: "default",
      },
    },
    undefined
  )

export default instance
