import React, { useContext } from "react"
import styled from "styled-components"
import { Bundle, Material } from "../../../axios/instances/interfaces"
import { StylesStateAction } from "../../../store/stylesState/interfaces"
import { StylesStateContext } from "../../../store/stylesState/StylesStateProvider"
import {
  BundleTheme,
  ThemeInterface,
  Variants,
} from "../../../styled/interfaces"
import { getVariantStyles } from "../../../styled/utils"
import { H6, P } from "../../common/texts"
import { ReactComponent as ImageMissing } from "../../../images/icons/image-missing.svg"

const ImageMissingWrapper = styled.div`
  min-width: 5rem;
  min-height: 5rem;
  max-width: 5rem;
  max-height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    width: 4rem;
    fill: var(--colorText);
    height: 4rem;
  }
`

const BundleImage = styled.img`
  min-width: 5rem;
  min-height: 5rem;
  max-width: 5rem;
  max-height: 5rem;
  object-fit: contain;
`

const ImageWrapper = styled.div`
  background: white;
  display: flex;
  align-items: center;
  width: 5rem;
  height: 100%;
`

const BundleWrapper = styled.div<{ active?: boolean }>(
  (props: { theme: ThemeInterface; variants?: Variants; active?: boolean }) => {
    const bundleStyles = getVariantStyles<BundleTheme>(
      props.theme.bundle,
      props.variants
    )

    return `
      width: 100%;
      max-width: 30rem;
      margin-right: 0.625rem;
      margin-bottom: 0.625rem;
      cursor: pointer;
      position: relative;
      transition: background-color 0.2s ease-in-out;

      ${
        bundleStyles.backgroundColor
          ? `background-color: ${bundleStyles.backgroundColor};`
          : ""
      }

      &:hover, &:active, &:focus {
        ${
          bundleStyles.backgroundColorHover
            ? `background-color: ${bundleStyles.backgroundColorHover};`
            : ""
        }
      }

      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: 5rem 1fr;
      justify-items: stretch;
      align-items: center;
    `
  }
)

const BundleInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  grid-gap: 0.3125rem;
  padding: 0.625rem;
`

interface BundleComponentProps {
  bundle: Bundle
}

const BundleComponent: React.FC<BundleComponentProps> = ({ bundle }) => {
  const { dispatch } = useContext(StylesStateContext)
  const displayedMaterial: Material | undefined =
    bundle.selectedMaterial ??
    bundle.defaultMaterial ??
    bundle.materialOptions[0]

  return (
    <BundleWrapper
      onClick={() =>
        dispatch({ type: StylesStateAction.SELECT_BUNDLE, data: bundle })
      }
      title={`${bundle.name}\n\n${displayedMaterial?.name || ""}`}
    >
      {displayedMaterial?.imageUrlSmall ? (
        <ImageWrapper>
          <BundleImage src={displayedMaterial?.imageUrlSmall} />
        </ImageWrapper>
      ) : (
        <ImageMissingWrapper>
          <ImageMissing />
        </ImageMissingWrapper>
      )}
      <BundleInfo>
        <H6 variants="small" className="material-name" lines={2}>
          {bundle.name}
        </H6>
        <P variants="small" className="material-description" lines={3}>
          {displayedMaterial?.name || ""}
        </P>
      </BundleInfo>
    </BundleWrapper>
  )
}

export default BundleComponent
