import React from "react"
import styled from "styled-components"
import { Variants } from "../../../styled/interfaces"
import { createVariantsArray } from "../../../styled/utils"
import Button from "../Button"

const DropdownContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  align-content: start;
  max-height: 100%;
  grid-template-columns: 1fr;
`

const ContentContainer = styled.div`
  overflow: hidden;
  justify-self: center;
  max-width: 100%;
  width: 100%;

  & > * {
    width: 100%;
    max-width: 100%;
    animation: appear-animation 0.5s ease-in-out;
  }
`

interface DropdownProps {
  onClick: () => void
  open: boolean
  children: React.ReactNode
  title: string
  variants?: Variants
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
}

const Dropdown: React.FC<DropdownProps> = ({
  open,
  onClick,
  title,
  children,
  variants,
  ...rest
}) => {
  const variantsArray = variants ? createVariantsArray(variants) : []

  const small = variantsArray.includes("small")

  return (
    <DropdownContainer {...rest}>
      <Button
        onClick={onClick}
        variants={{
          submenu: true,
          small,
        }}
        active={open}
        style={{
          display: "flex",
          alignItems: "center",
          padding: small ? "0 0.3125rem" : undefined,
        }}
      >
        {title}
      </Button>
      <ContentContainer>{open ? children : null}</ContentContainer>
    </DropdownContainer>
  )
}

export default Dropdown
