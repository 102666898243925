import React from "react";
import Button from "../../common/Button";
import { t } from "../../../utils";
import { ReactComponent as Done } from "../../../images/icons/done.svg";
import { ReactComponent as LockIcon } from "../../../images/icons/lock.svg";

interface MaterialSelectionButtonProps {
  isSelected?: boolean;
  isBundleLocked: boolean;
  onSelect: () => void;
}

const MaterialSelectionButton: React.FC<MaterialSelectionButtonProps> = ({
  isSelected,
  isBundleLocked,
  onSelect,
}) => {
  const IconForMaterialButton = ({ style }: any) => {
    return (
      <>
        {isBundleLocked ? <LockIcon style={style} /> : <Done style={style} />}
      </>
    );
  };
  return (
    <Button
      variants="small noRounding"
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onSelect();
      }}
      disabled={isSelected}
    >
      {isSelected ? t("theming.chosen") : t("theming.choose")}
      {isSelected && (
        <IconForMaterialButton
          style={{
            marginLeft: "0.625rem",
            marginTop: "-1rem",
            marginBottom: "-1rem",
          }}
        />
      )}
    </Button>
  );
};
export default MaterialSelectionButton;
