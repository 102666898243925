import React from "react"
import styled from "styled-components"
import { ReactComponent as CameraIcon } from "../../images/icons/camera.svg"
import { mediaPortrait, mediaQuery } from "../../styled/mediaQueries"
import Button from "../common/Button"
import CircularSpinner from "../Spinner/CircularSpinner"

const ButtonStyled = styled(Button)<{ showFloors: boolean }>`
  position: absolute;
  left: 0;
  transform: translateX(-100%);
  bottom: 0;

  ${mediaQuery(mediaPortrait)} {
    transform: translateX(calc(-100%));

    ${({ showFloors }) =>
      !showFloors
        ? `
    transform: translateX(calc(-50%));
  `
        : ""}

    ${({ showControls }) =>
      showControls
        ? `
    transform: translateX(calc(-100% - 1rem * var(--button-size-factor)));
  `
        : ""}
  }

  ${({ showFloors }) =>
    showFloors
      ? `
    transform: translateX(calc(-200% - 1rem * var(--button-size-factor)));
  `
      : ""}

  ${({ showControls }) =>
    showControls
      ? `
    transform: translateX(calc(-100% - 2rem * var(--button-size-factor)));
  `
      : ""}
`

interface CameraButtonProps {
  onClick: () => void
  inProgress: boolean
  showFloors: boolean
  showControls: boolean
}

const CameraButton: React.FC<CameraButtonProps> = ({
  onClick,
  inProgress,
  showFloors,
  showControls,
}) => {
  return (
    <ButtonStyled
      showFloors={showFloors}
      variants="controlButton rounded"
      onClick={onClick}
      showControls={showControls}
    >
      {inProgress ? (
        <CircularSpinner size={0.5} />
      ) : (
        <CameraIcon height="60px" width="60px" />
      )}
    </ButtonStyled>
  )
}

export default CameraButton
