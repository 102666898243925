import { StartUrlParametersInterface } from "../store/globalState/interfaces"
import { isMobile } from 'react-device-detect';

export enum StartUrlParameters {
  projectId = "projectId",
  apartmentId = "apartmentId",
  bim = "bim",
  schema = "schema",
  company = "company",
  api = "api",
  stage = "stage",
  lan = "lan",
  username = "username",
  password = "password",
  accessToken = "accessToken",
  authToken = "authToken",
  RenderUi = "RenderUi",
  renderUi = "renderUi",
  debug = "debug",
  isMobile = "isMobile",
}

export const StartUrlParametersArray: string[] = [
  StartUrlParameters.projectId,
  StartUrlParameters.apartmentId,
  StartUrlParameters.bim,
  StartUrlParameters.schema,
  StartUrlParameters.company,
  StartUrlParameters.api,
  StartUrlParameters.stage,
  StartUrlParameters.lan,
  StartUrlParameters.username,
  StartUrlParameters.password,
  StartUrlParameters.accessToken,
  StartUrlParameters.authToken,
  StartUrlParameters.RenderUi,
  StartUrlParameters.debug,
  StartUrlParameters.isMobile,
]

const unsafeParameters: string[] = [
  StartUrlParameters.username,
  StartUrlParameters.password,
]

export const getStartParameters: () => StartUrlParametersInterface = () => {

  // Remove previously stored data from Localstorage
  if (window.localStorage) {
    window.localStorage.clear()
  }

  const urlParams = Object.fromEntries(
    new URLSearchParams(window.location.search.substring(1))
  )

  if (urlParams[StartUrlParameters.lan]) {
    window.sessionStorage.setItem("i18nextLng", urlParams[StartUrlParameters.lan])
    delete urlParams[StartUrlParameters.lan]
  }

  const isDebug = !!urlParams[StartUrlParameters.debug]

  const newParams = !!Object.keys(urlParams).length && !isDebug

  if (window.sessionStorage) {
    window.sessionStorage.setItem("isMobile", isMobile.toString())
  }
  
  const sessionStorageParams: { [x: string]: string } | null = newParams
    ? null
    : StartUrlParametersArray.reduce((lsParams, cur) => {
        const param = window.sessionStorage.getItem(cur)
        if (param) lsParams[cur] = param
        return lsParams
      }, {} as { [x: string]: string })

  if (newParams && !isDebug) {
    StartUrlParametersArray.forEach(param =>
      window.sessionStorage.removeItem(param)
    )
  }

  for (const [key, value] of Object.entries(urlParams)) {
    if (!StartUrlParametersArray.includes(key)) {
      delete urlParams[key]
    } else if (
      !unsafeParameters.includes(key) &&
      key !== StartUrlParameters.debug
    )
      window.sessionStorage.setItem(key, value)
  }

  const startParams = { ...sessionStorageParams, ...urlParams }

  if (!isDebug)
    window.history.replaceState(null, "", `${window.location.pathname}`)
  else
    window.history.replaceState(
      null,
      "",
      `${window.location.pathname}?${new URLSearchParams(
        startParams
      ).toString()}`
    )

  return startParams
}

export const getLsParams: () => StartUrlParametersInterface = () => {
  return StartUrlParametersArray.reduce((lsParams, cur) => {
    const param = window.sessionStorage.getItem(cur)
    if (param) lsParams[cur] = param
    return lsParams
  }, {} as { [x: string]: string })
}

export default StartUrlParameters
