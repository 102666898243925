import React, { useState } from "react"
import styled from "styled-components"
import { ReactComponent as LayoutIcon } from "../../images/icons/layout.svg"
import { Variants } from "../../styled/interfaces"
import {
  mediaLandscape,
  mediaPortrait,
  mediaQuery,
} from "../../styled/mediaQueries"
import Button from "../common/Button"

const ButtonStyled = styled(Button)`
  &.number {
    width: 100%;
    aspect-ratio: 1/1;
    width: calc(84px * var(--button-size-factor));
    transform: translateY(var(2rem * var(--button-size-factor)));

    &:not(:last-child) {
      margin-bottom: calc(1rem * var(--button-size-factor));
    }
  }
`

const ButtonsContainer = styled.div<{ showFloors: boolean }>`
  display: flex;
  flex-direction: column;
  position: absolute;
  transform: translateY(calc(-100% - 2rem * var(--button-size-factor)));
  right: 0;

  ${mediaQuery(mediaPortrait)} {
    transform: translateX(calc(100% + 1rem * var(--button-size-factor)))
      translateY(calc(-2rem * var(--button-size-factor)));
    bottom: 0;
    align-items: center;

    ${({ showFloors }) =>
      !showFloors
        ? `
      transform: translateX(calc(100% + 1rem))
    `
        : `
        transform: translateX(calc(100% + 1rem * var(--button-size-factor)));
        `}
  }

  ${mediaQuery(mediaLandscape)} {
    ${({ showFloors }) =>
      showFloors
        ? `
        transform: translateY(-100%);
    `
        : ""}
  }
`

interface LayoutButtonProps {
  floors: number
  selectFloor: (floorIndex: number) => void
  resetCamera: () => void
  variants: Variants
  showFloors: boolean
}

const LayoutButton: React.FC<LayoutButtonProps> = ({
  floors,
  selectFloor,
  resetCamera,
  showFloors,
}) => {
  const isMultiFloor = floors > 1
  const [activeFloor, setActiveFloor] = useState(floors + 1)

  return (
    <ButtonsContainer showFloors={showFloors && floors > 1}>
      {showFloors &&
        isMultiFloor &&
        new Array(floors)
          .fill("")
          .map((_, i) => i + 1)
          .reverse()
          .map(index => (
            <ButtonStyled
              variants="controlButton rounded bold"
              className="number"
              active={activeFloor === index}
              onClick={() => {
                setActiveFloor(index)
                selectFloor(index - 1)
              }}
              key={index}
            >
              {index}.
            </ButtonStyled>
          ))}
      {!showFloors && (
        <ButtonStyled
          variants="controlButton rounded"
          onClick={() => resetCamera()}
        >
          <LayoutIcon height="60px" width="60px" />
        </ButtonStyled>
      )}
    </ButtonsContainer>
  )
}

export default LayoutButton
