import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import "normalize.css"
import GlobalStateProvider from "./store/globalState/GlobalStateProvider"
import { ThemeProvider } from "styled-components"
import mainTheme from "./styled/theme"
import GlobalStyle from "./styled/createGlobalStyle"

import "./i18n"
import firebase from "firebase/app"
import "firebase/analytics"
import DialogStateProvider from "./store/dialogState/DialogStateProvider"
import Dialog from "./components/common/Dialog/Dialog"
import './index.css'

const firebaseConfig = {
  apiKey: "AIzaSyAXX_D9rBdrc1Uvf-tDQ6qDKTkeG6C0xsM",
  authDomain: "gbuilder-hd3d.firebaseapp.com",
  databaseURL: "https://gbuilder-hd3d.firebaseio.com",
  projectId: "gbuilder-hd3d",
  storageBucket: "gbuilder-hd3d.appspot.com",
  messagingSenderId: "362788248021",
  appId: "1:362788248021:web:b1048e454933d1cea1c55c",
  measurementId: "G-E1J0TFPSDL",
} 

firebase.initializeApp(firebaseConfig)
firebase.analytics()

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={mainTheme}>
      <GlobalStyle />
      <GlobalStateProvider>
        <DialogStateProvider>
          <App />
          <Dialog />
        </DialogStateProvider>
      </GlobalStateProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
