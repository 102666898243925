import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Material } from "../../../axios/instances/interfaces";
import { StylesStateAction } from "../../../store/stylesState/interfaces";
import { StylesStateContext } from "../../../store/stylesState/StylesStateProvider";
import {
  BundleTheme,
  ThemeInterface,
  Variants,
} from "../../../styled/interfaces";
import { getVariantStyles } from "../../../styled/utils";
import { H6, P } from "../../common/texts";
import { ReactComponent as ImageMissing } from "../../../images/icons/image-missing.svg";
import MaterialSelectionButton from "./MaterialSelectionButton";
import { BundleState } from "../../../axios/instances/interfaces";

const ImageMissingWrapper = styled.div`
  min-width: 5rem;
  min-height: 5rem;
  max-width: 5rem;
  max-height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    width: 4rem;
    fill: var(--colorText);
    height: 4rem;
  }
`;

const MaterialImage = styled.img`
  min-width: 5rem;
  min-height: 5rem;
  max-width: 5rem;
  max-height: 5rem;
  object-fit: contain;
`;

const ImageWrapper = styled.div`
  width: 5rem;
  height: 100%;
  padding: 0.625rem;
  display: flex;
  align-items: flex-start;
`;

const MaterialWrapper = styled.div<{ active?: boolean }>(
  (props: { theme: ThemeInterface; variants?: Variants; active?: boolean }) => {
    const bundleStyles = getVariantStyles<BundleTheme>(
      props.theme.bundle,
      props.variants
    );

    return `
      width: 100%;
      max-width: 30rem;
      margin-right: 0.625rem;
      margin-bottom: 0.625rem;
      cursor: pointer;
      position: relative;
      transition: background-color 0.2s ease-in-out;
      ${
        bundleStyles.backgroundColor
          ? `
          background-color: ${bundleStyles.backgroundColor};
          `
          : ""
      }

      ${
        bundleStyles.backgroundColorHover && props.active
          ? `background-color: ${bundleStyles.backgroundColorHover};`
          : ""
      }

      &:hover, &:active, &:focus {
        ${
          bundleStyles.backgroundColorHover
            ? `background-color: ${bundleStyles.backgroundColorHover};`
            : ""
        }
      }

      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: 5rem 1fr;
      gap: 0.625rem;
      justify-items: stretch;

      &.previewed, &.selected {
        grid-template-rows: 1fr auto;
      }
    `;
  }
);

const MaterialInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  grid-gap: 0.625rem;
  padding: 0.625rem;
`;

const SelectButtonRow = styled.div`
  grid-column: 2;
  display: flex;
  padding: 0.625rem;
  gap: 0.625rem;
  align-items: center;
  justify-content: flex-end;
`;

interface MaterialComponentProps {
  material: Material;
  isSelected?: boolean;
  isPreviewed?: boolean;
  bundleId: number;
  onSelect?: () => void;
}

const MaterialComponent: React.FC<MaterialComponentProps> = ({
  material,
  isSelected,
  isPreviewed,
  onSelect,
  bundleId,
}) => {
  const { stylesState, dispatch: stylesStateDispatch } =
    useContext(StylesStateContext);

  const isBundleLocked =
    stylesState?.selectedBundle?.state === BundleState.LOCKED ||
    stylesState?.selectedBundle?.state === BundleState.CONFIRMED;

  return (
    <MaterialWrapper
      title={`${material.name}\n\n${material.description}`}
      className={
        isPreviewed ? "previewed" : isSelected ? "selected" : undefined
      }
      onClick={() => {
        stylesStateDispatch({
          type: StylesStateAction.PREVIEW_MATERIAL,
          data: {
            bundleId,
            materialId: material.id,
          },
        });
      }}
      active={isPreviewed}
    >
      {material.imageUrlLarge ? (
        <ImageWrapper>
          <MaterialImage src={material.imageUrlLarge} />
        </ImageWrapper>
      ) : (
        <ImageMissingWrapper>
          <ImageMissing />
        </ImageMissingWrapper>
      )}
      <MaterialInfo>
        <H6 variants="small" className="material-name" lines={2}>
          {material.name}
        </H6>
        <P
          variants="small"
          className="material-description"
          lines={!isPreviewed ? 3 : undefined}
        >
          {material.description}
        </P>
      </MaterialInfo>
      {(isPreviewed || isSelected) && onSelect && (
        <SelectButtonRow>
          {isBundleLocked && isSelected ? (
            <MaterialSelectionButton
              onSelect={onSelect}
              isSelected={isSelected}
              isBundleLocked={isBundleLocked}
            />
          ) : null}
          {!isBundleLocked && (
            <MaterialSelectionButton
              onSelect={onSelect}
              isSelected={isSelected}
              isBundleLocked={isBundleLocked}
            />
          )}
        </SelectButtonRow>
      )}
    </MaterialWrapper>
  );
};

export default MaterialComponent;
