import React from "react"
import { Bundle, Room } from "../../../axios/instances/interfaces"
import RoomMaterials from "./RoomMaterials"
import styled from "styled-components"

interface RoomComponentProps {
  room: Room | null
  bundles: Bundle[]
}

const RoomComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const RoomComponent: React.FC<RoomComponentProps> = ({ room, bundles }) => {
  return (
    <RoomComponentWrapper>
      <RoomMaterials bundles={bundles} hideTitles={!room?.name} />
    </RoomComponentWrapper>
  )
}

export default RoomComponent
