import { makeId } from "../../utils"
import {
  SnackbarState,
  SnackbarStateAction,
  SnackbarStateActionType,
} from "./interfaces"

const SnackbarStateReducer = (
  snackbarState: SnackbarState,
  action: SnackbarStateActionType
): SnackbarState => {
  switch (action.type) {
    case SnackbarStateAction.SHOW_SNACKBAR_ALERT:
      return {
        ...snackbarState,
        snackbars: [
          {
            ...action.data,
            id: makeId(10),
          },
        ],
      }
    case SnackbarStateAction.HIDE_SNACKBAR_ALERT:
      return {
        ...snackbarState,
        snackbars: snackbarState.snackbars.filter(
          snackbar => snackbar.id !== action.data
        ),
      }
    default:
      throw new Error(`Bad reducer action.`)
  }
}

export default SnackbarStateReducer
