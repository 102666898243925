import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { Package } from "../../../axios/instances/interfaces"
import Button from "../../common/Button"
import { ReactComponent as ImageMissing } from "../../../images/icons/image-missing.svg"
import { usePackageSelect } from "../../../hooks/usePackageSelect"
import { StylesStateContext } from "../../../store/stylesState/StylesStateProvider"
import { StylesStateAction } from "../../../store/stylesState/interfaces"
import { ReactComponent as Done } from "../../../images/icons/done.svg"
import { H6 } from "../../common/texts/H6"

import {
  mediaHeightLDown,
  mediaMediumDown,
  mediaQuery,
  mediaHeightXSDown,
} from "../../../styled/mediaQueries"
import { t } from "../../../utils"

const PackagesDisplayStyled = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto minmax(0, 1fr) auto;
  justify-items: center;
  height: 100%;

  ${mediaQuery(`${mediaMediumDown}, ${mediaHeightLDown}`)} {
    width: auto;
    display: grid;
    grid-template-columns: auto;
    grid-auto-rows: auto;
    justify-content: flex-start;
  }
`

const PackageImageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

const PackageButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  & > * {
    margin-right: calc(0.625rem * var(--button-size-factor));
    margin-left: calc(0.625rem * var(--button-size-factor));
    margin-bottom: calc(1.25rem * var(--button-size-factor));
  }
`

const H6Styled = styled(H6)`
  position: absolute;
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
  max-width: 100%;
  bottom: calc(0.3125rem * var(--size-factor));
  padding: calc(0.3125rem * var(--size-factor))
    calc(0.625rem * var(--size-factor));
  background: rgba(255, 255, 255, 0.9);
`

const PackageImage = styled.img`
  align-self: center;
  min-height: 5rem;
  object-fit: contain;
  max-width: 100%;
  height: 100%;
  aspect-ratio: 16 / 9;

  ${mediaQuery(`${mediaMediumDown}, ${mediaHeightLDown}`)} {
    max-height: 50vh;
    justify-self: center;
  }
  ${mediaQuery(`${mediaHeightXSDown}`)} {
    max-height: 30vh;
  }
`

const ImageMissingContainer = styled.div`
  height: calc(17.5rem * var(--button-size-factor));
  & > svg {
    fill: var(--colorText);
    height: calc(17.5rem * var(--button-size-factor));
    width: auto;
  }
`

interface PackagesDisplayProps {
  packages: Package[]
  selectPackage: (packageId: number) => void
}

const PackagesDisplay: React.FC<PackagesDisplayProps> = ({
  packages,
  selectPackage,
}) => {
  const apiSelectPackage = usePackageSelect()
  const { dispatch } = useContext(StylesStateContext)
  const [previewedPackage, setPreviewedPackage] = useState<Package | null>(null)

  const doSelectPackage = async (theme: Package) => {
    dispatch({ type: StylesStateAction.SELECT_PACKAGE, data: theme })  
    theme.canSelect && (await apiSelectPackage?.(theme.id.toString()))
    selectPackage(theme.id)
  }

  const selectedPackage = packages.find(theme => theme.selected) || null

  useEffect(() => {
    if (!selectedPackage && packages[0]) setPreviewedPackage(packages[0])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isPreview = !(apiSelectPackage && selectedPackage?.canSelect)

  return (
    <PackagesDisplayStyled>
      <PackageButtonsWrapper>
        {packages.map(theme => (
          <Button
            variants="tab small"
            key={theme.id}
            onClick={() => setPreviewedPackage(theme)}
            active={previewedPackage?.id === theme.id}
          >
            {theme.name}
            {theme.selected && (
              <Done
                style={{
                  marginLeft: "0.625rem",
                  marginTop: "-1rem",
                  marginBottom: "-1rem",
                }}
              />
            )}
          </Button>
        ))}
      </PackageButtonsWrapper>
      {previewedPackage && (
        <>
          <PackageImageContainer>
            {previewedPackage.images[0]?.url ? (
              <PackageImage
                src={previewedPackage.images[0]?.url}
                alt={previewedPackage.name}
              />
            ) : (
              <ImageMissingContainer>
                <ImageMissing />
              </ImageMissingContainer>
            )}
            <H6Styled>
              {`${previewedPackage.displayName || previewedPackage.name}
            ${isPreview ? `(${t("theming.preview2")})` : ""}`}
            </H6Styled>
          </PackageImageContainer>
          <Button
            style={{
              marginBottom: "0.625rem",
              alignSelf: "center",
              marginTop: "0.625rem",
            }}
            variants="small noRounding"
            onClick={() => doSelectPackage(previewedPackage)}
            active={previewedPackage.id === selectedPackage?.id}
            disabled={
              previewedPackage.id === selectedPackage?.id ||
              !previewedPackage.canPreview
            }
          >
            {apiSelectPackage && previewedPackage.canSelect
              ? t(
                  previewedPackage.selected
                    ? "theming.chosen"
                    : "theming.choose"
                )
              : t("theming.preview")}
            {previewedPackage.id === selectedPackage?.id && (
              <Done
                style={{
                  marginLeft: "0.625rem",
                  marginTop: "-1rem",
                  marginBottom: "-1rem",
                }}
              />
            )}
          </Button>
        </>
      )}
    </PackagesDisplayStyled>
  )
}

export default PackagesDisplay
