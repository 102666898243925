import React, { useState } from "react"
import styled from "styled-components"
import { ReactComponent as GlobeIcon } from "../../images/icons/language.svg"
import { Languages as languages } from "../../i18n"
import Paper from "../common/Paper/Paper"
import Button from "../common/Button"
import i18next from "i18next"
import {
  mediaQuery,
  mediaMediumDown,
  mediaHeightLDown,
} from "../../styled/mediaQueries"

const LanguageSelectorContainer = styled.div<{ isMobile?: boolean }>`
  position: fixed;
  top: calc(var(--button-size-factor) * 1.875rem);
  right: calc(var(--button-size-factor) * 1.875rem);
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${({ isMobile }) =>
    isMobile
      ? `
      display: none;
    ${mediaQuery(`${mediaMediumDown}, ${mediaHeightLDown}`)} {
      display: flex;
    }
  `
      : ""}
`

const LanguagesList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: calc(var(--button-size-factor) * 0.625rem)
    calc(var(--button-size-factor) * 1.25rem);

  button {
    line-height: 1.8;
  }
`

const StyledGlobeIcon = styled(GlobeIcon)`
  fill: var(--colorMain);
  width: calc(var(--button-size-factor) * 2.5rem);
  height: calc(var(--button-size-factor) * 2.5rem);
`

interface LanguageSelectorProps {
  isMobile?: boolean
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({ isMobile }) => {
  const [showLanguages, setShowLanguages] = useState(false)
  return (
    <LanguageSelectorContainer isMobile={isMobile}>
      <Button
        style={{ marginBottom: "calc(0.625rem * var(--button-size-factor))" }}
        variants="smallPadding transparent"
        onClick={() => setShowLanguages(!showLanguages)}
      >
        <StyledGlobeIcon />
      </Button>
      {showLanguages && (
        <Paper variants="noPadding smallerRadius appear">
          <LanguagesList>
            {languages.map(language => (
              <Button
                variants="text small"
                onClick={() => {
                  i18next.changeLanguage(language.code)
                  window.location.reload()
                }}
                key={language.title}
              >
                {language.title}
              </Button>
            ))}
          </LanguagesList>
        </Paper>
      )}
    </LanguageSelectorContainer>
  )
}

export default LanguageSelector
