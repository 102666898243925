import styled from "styled-components"
import { Variants } from "../../styled/interfaces"
import React, { useMemo } from "react"

const BackgroundStyled = styled.div<{
  variants?: Variants
  background?: string
}>(props => {
  return `
    width: 100%;
    height: 100%;
    background-color: ${props.theme.application.colors.background};
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    ${
      props.theme.application.mainBackgroundImage
        ? `background: center / cover no-repeat url(${
            props.background || props.theme.application.mainBackgroundImage
          })`
        : ""
    };
  `
})

interface BackgroundProps {
  children: React.ReactNode
  variants?: Variants
  background?: string
}

const Background: React.FC<BackgroundProps> = ({
  children,
  variants,
  background,
}) => {
  return useMemo(
    () => (
      <BackgroundStyled variants={variants} background={background}>
        {children}
      </BackgroundStyled>
    ),
    [background, variants, children]
  )
}

export default Background
