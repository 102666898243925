import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }),
);

interface UserNoteProps {
  variant: 'filled' | 'outlined' | 'standard'
  severity: 'error' | 'info' | 'success'
  alertText: string
}

const UserNote: React.FC<UserNoteProps> = ({ variant, severity, alertText }) => {

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Alert variant={variant} severity={severity}>
        {alertText}
      </Alert>
    </div>
  )
}

export default UserNote;
