import { StylesState } from "./interfaces"

const initialStylesState: StylesState = {
  packageSets: [],
  fetchingStyles: false,
  stylesFetched: false,
  bundles: [],
  selectedBundle: null,
  fetchingMaterials: false,
  materialsFetched: false,
  rooms: [],
  openRoom: -1,
  fetchingRooms: false,
  roomsFetched: false,
  activeTab: null,
  selectedPackage: null,
}

export default initialStylesState
