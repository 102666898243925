import React, { useContext } from "react"
import styled from "styled-components"
import { SnackbarStateAction } from "../../store/snackbarState/interfaces"
import { SnackbarStateContext } from "../../store/snackbarState/SnackbarStateProvider"
import Snackbar from "../Snackbar/Snackbar"

const SnackbarsContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  width: 40rem;
  max-width: calc(100% - 3.75rem);
  align-items: center;
`

const SnackbarsRenderer: React.FC = () => {
  const { snackbarState, dispatch } = useContext(SnackbarStateContext)
  const snackbars = snackbarState.snackbars

  return (
    <SnackbarsContainer>
      {snackbars.map(snackbar => (
        <Snackbar
          key={snackbar.id}
          time={snackbar.time}
          message={snackbar.message}
          dismiss={() =>
            dispatch({
              type: SnackbarStateAction.HIDE_SNACKBAR_ALERT,
              data: snackbar.id,
            })
          }
          dismissable={snackbar.dismissable}
          isError={snackbar.isError}
        />
      ))}
    </SnackbarsContainer>
  )
}

export default SnackbarsRenderer
