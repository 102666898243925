import React, { useContext, useEffect } from "react"
import styled from "styled-components"
import { Bundle } from "../../../axios/instances/interfaces"
import { H6 } from "../../common/texts"
import MaterialComponent from "./MaterialComponent"
import { useMaterialSelect } from "../../../hooks/useMaterialSelect"
import { StylesStateContext } from "../../../store/stylesState/StylesStateProvider"
import { StylesStateAction } from "../../../store/stylesState/interfaces"

const SelectedBundleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 100%;
  overflow-y: auto;

  & > ${H6} {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
`

const MaterialsFlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`

interface SelectedBundleProps {
  bundle: Bundle
}

const SelectedBundle: React.FC<SelectedBundleProps> = ({ bundle }) => {
  const apiSelectMaterial = useMaterialSelect()
  const { dispatch } = useContext(StylesStateContext)

  const selectedMaterial =
    bundle.selectedMaterial ??
    bundle.defaultMaterial ??
    bundle.materialOptions[0]

  const canSelect = bundle.userCanSelectMaterial && apiSelectMaterial

  useEffect(() => {
    return () =>
      dispatch({
        type: StylesStateAction.RESET_PREVIEW,
      })
  }, [])

  return (
    <SelectedBundleContainer>
      <MaterialsFlexContainer>
        {bundle.state !== "LOCKED" ? (
          bundle.materialOptions.map(material => (
            <MaterialComponent
              bundleId={bundle.id}
              onSelect={
                canSelect
                  ? () => apiSelectMaterial?.(material, bundle.id.toString())
                  : undefined
              }
              isSelected={material.id === bundle.selectedMaterial?.id}
              isPreviewed={
                material.id ===
                (bundle.previewedMaterial?.id || selectedMaterial.id)
              }
              material={material}
              key={material.id}
            />
          ))
        ) : (
          <MaterialComponent
            bundleId={bundle.id}
            isSelected
            isPreviewed
            material={selectedMaterial}
          />
        )}
      </MaterialsFlexContainer>
    </SelectedBundleContainer>
  )
}

export default SelectedBundle
