export enum SnackbarStateAction {
  SHOW_SNACKBAR_ALERT = "SHOW_SNACKBAR_ALERT",
  HIDE_SNACKBAR_ALERT = "HIDE_SNACKBAR_ALERT",
}

export interface SnackbarOptions {
  message: string
  isError?: boolean
  time?: number
  dismissable?: boolean
  id: string
}

export interface SnackbarState {
  snackbars: Array<SnackbarOptions>
}

export interface ShowSnackbarAlertAction {
  type: SnackbarStateAction.SHOW_SNACKBAR_ALERT
  data: Omit<SnackbarOptions, "id">
}

export interface HideSnackbarAlertAction {
  type: SnackbarStateAction.HIDE_SNACKBAR_ALERT
  data: string
}

export type SnackbarStateReducerType = (
  state: SnackbarState,
  action: SnackbarStateActionType
) => SnackbarState

export type SnackbarStateActionType =
  | ShowSnackbarAlertAction
  | HideSnackbarAlertAction
