import { createGlobalStyle } from "styled-components"
import { ThemeInterface } from "./interfaces"
import {
  mediaHeightXLUp,
  mediaHeightLUp,
  mediaHeightSUp,
  mediaHeightMUp,
  mediaLargeUp,
  mediaMediumUp,
  mediaQuery,
  mediaSmallUp,
  mediaXLargeUp,
  mediaXSmallUp,
  mediaMediumDown,
  mediaHeightLDown,
  mediaHeightXSDown,
} from "./mediaQueries"

const GlobalStyle = createGlobalStyle<{ theme: ThemeInterface }>`
  :root {
    box-sizing: border-box;
    background: ${({ theme }) => theme.application.colors.background};
    font-size: 16px;
    font-family: Montserrat, sans-serif;
    color: ${({ theme }) => theme.application.colors.text};
  }
  #root {
    height: 100vh;
  }

  body {
    min-height: 100vh;
    font-size: 1.375rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;

    /* Responsive sizing when needed, used for text atleast */
    --size-factor: 0.6;
    --button-size-factor: 0.6;
    --margin-factor: 1;

    --colorMain: ${({ theme }) => theme.application.colors.main};
    --colorError: ${({ theme }) => theme.application.colors.error};
    --colorBorder: ${({ theme }) => theme.application.colors.border};
    --colorText: ${({ theme }) => theme.application.colors.text};
    --colorStreamBackground: ${({ theme }) =>
      theme.application.colors.streamBackground};
    --colorBackground: ${({ theme }) => theme.application.colors.background};
    
    ${mediaQuery(mediaMediumDown)}{
      --margin-factor: 0.5;
    }
    ${mediaQuery(mediaHeightLDown)} {
      --margin-factor: 0.25;
    }

    ${mediaQuery(`${mediaXSmallUp} and ${mediaHeightSUp}`)} {
      --size-factor: 0.8;
      --button-size-factor: 0.7;
    }
    ${mediaQuery(`${mediaSmallUp} and ${mediaHeightMUp}`)} {
      --size-factor: 1;
      --button-size-factor: 0.75;
    }
    ${mediaQuery(`${mediaMediumUp} and ${mediaHeightLUp}`)} {
      --size-factor: 1.1;
      --button-size-factor: 0.8;
    }
    ${mediaQuery(`${mediaLargeUp} and ${mediaHeightXLUp}`)} {
      --button-size-factor: 0.9;
      --size-factor: 1.3;
    }
    ${mediaQuery(`${mediaXLargeUp} and ${mediaHeightXLUp}`)} {
      --button-size-factor: 1;
      --size-factor: 1.4;
    }

    ${mediaQuery(mediaHeightXSDown)} {
      --button-size-factor: 0.45;
    }
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  h1, h2, h3, h4, h5, h6, p, small {
    margin-block-start: 0;
    margin-block-end: 0;
  }

  ul {
    list-style-type: none;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;
  }

  @keyframes appear-animation {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

export default GlobalStyle
