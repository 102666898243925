import React, { useEffect } from "react"
import styled from "styled-components"
import {
  SnackbarTheme,
  ThemeInterface,
  Variants,
} from "../../styled/interfaces"
import { getVariantStyles } from "../../styled/utils"
import Button from "../common/Button"
import { P } from "../common/texts"
import { ReactComponent as CloseIcon } from "../../images/icons/close.svg"

const SnackbarStyled = styled.div(
  (props: {
    theme: ThemeInterface
    variants?: Variants
    children: React.ReactNode
  }) => {
    const snackbarStyles = getVariantStyles<SnackbarTheme>(
      props.theme.snackbar,
      props.variants
    )

    return `
      display: flex;
      align-items: center;
      ${
        snackbarStyles.borderColor
          ? `border-color: ${snackbarStyles.borderColor};`
          : ""
      }
      ${
        snackbarStyles.background
          ? `background: ${snackbarStyles.background};`
          : ""
      }
      ${
        snackbarStyles.spacing
          ? `padding: calc(${snackbarStyles.spacing} * var(--button-size-factor));`
          : ""
      }
      ${
        snackbarStyles.borderRadius
          ? `border-radius: ${snackbarStyles.borderRadius};`
          : ""
      }
      ${
        snackbarStyles.borderStyle
          ? `border-style: ${snackbarStyles.borderStyle};`
          : ""
      }
      ${
        snackbarStyles.borderWidth
          ? `border-width: ${snackbarStyles.borderWidth};`
          : ""
      }

      &>Button {
        ${
          snackbarStyles.spacing
            ? `margin-left: calc(${snackbarStyles.spacing} * var(--button-size-factor));`
            : ""
        }
      }
    `
  }
)
interface SnackbarProps {
  time?: number
  dismissable?: boolean
  dismiss: () => void
  message: string
  isError?: boolean
}

const Snackbar: React.FC<SnackbarProps> = ({
  time,
  dismissable,
  dismiss,
  message,
  isError,
}) => {
  useEffect(() => {
    if (time) {
      const to = setTimeout(() => dismiss(), time)

      return () => {
        clearTimeout(to)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return message ? (
    <SnackbarStyled variants={{ error: !!isError }}>
      <P variants="s thin">{message}</P>
      {dismissable && (
        <Button variants="icon" onClick={dismiss}>
          <CloseIcon />
        </Button>
      )}
    </SnackbarStyled>
  ) : null
}

export default Snackbar
