export interface Link {
  blank: boolean
  text: string
  href: string
}

export interface File {
  file: string
  name: string
}

export interface DialogState {
  show: boolean
  title: string | null
  text: string | null
  link: Link | null
  file: File | null
  onCancel: (() => void) | null
  onCancelText: null | string
  onAccept: (() => void) | null
  onAcceptText: null | string
}

export enum DialogStateAction {
  OPEN_DIALOG = "OPEN_DIALOG",
  CLOSE_DIALOG = "CLOSE_DIALOG",
}

export interface OpenDialogAction {
  type: DialogStateAction.OPEN_DIALOG
  data: Partial<DialogState>
}

export interface CloseDialogAction {
  type: DialogStateAction.CLOSE_DIALOG
}

export type DialogStateActionType = OpenDialogAction | CloseDialogAction

export type DialogStateReducerType = (
  state: DialogState,
  action: DialogStateActionType
) => DialogState
