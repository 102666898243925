import { GlobalState } from "./interfaces"

const initialGlobalState: GlobalState = {
  hasThreeDStarted: false,
  startParameters: {},
  tenantSettings: {},
  token: null,
  applicationError: null,
  apartmentInfo: {},
}

export default initialGlobalState
