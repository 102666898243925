import React, { Dispatch, useContext } from "react"
import styled from "styled-components"
import { GlobalStateContext } from "../../store/globalState/GlobalStateProvider"
import {
  GlobalStateAction,
  SetHasThreeDStartedAction,
} from "../../store/globalState/interfaces"
import Button, { ButtonStyled } from "../common/Button"
import { t } from "../../utils"
import { H1, H4, P, Small } from "../common/texts"
import Logo, { LogoImg } from "../Logo/Logo"
import {
  mediaHeightLDown,
  mediaHeightMDown,
  mediaHeightSDown,
  mediaLargeDown,
  mediaMediumUp,
  mediaQuery,
  mediaSmallDown,
} from "../../styled/mediaQueries"
import Paper from "../common/Paper/Paper"

const WelcomeTitleContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: end;
  margin-bottom: 2.5rem;
  margin-top: 2.5rem;

  ${mediaQuery(mediaHeightLDown)} {
    margin-bottom: 1.25rem;
    margin-top: 1.25rem;
  }
`

const ThreeDDescription = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2.5rem;
  align-self: start;
  max-width: 52rem;
  white-space: pre-line;

  ${mediaQuery(mediaHeightLDown)} {
    margin-top: 1.25rem;
  }

  ${mediaQuery(mediaHeightSDown)} {
    margin-top: 0.675rem;
  }
`

const WelcomeContent = styled.div`
  width: 28rem;
  max-width: calc(100% - 3.75rem);
  text-align: center;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto auto;

  justify-items: center;
  align-items: center;

  ${mediaQuery(`${mediaHeightLDown} and ${mediaSmallDown}`)} {
    grid-template-rows: auto auto auto auto;
  }

  ${mediaQuery(mediaHeightMDown)} {
    width: 100%;
    grid-template-rows: auto auto auto;
    grid-template-columns: auto auto;
    align-items: top;

    ${ButtonStyled}, ${WelcomeTitleContainer}, ${ThreeDDescription} {
      grid-column: 1 / -1;
    }

    ${LogoImg} {
      position: absolute;
      top: 0.625rem;
      left: 0.625rem;
    }
  }

  ${mediaQuery(mediaMediumUp)} {
    width: 60rem;
  }

  ${mediaQuery(`${mediaLargeDown} and ${mediaHeightMDown}`)} {
    ${LogoImg} {
      width: 4rem;
    }
  }

  & > * {
    z-index: 1;
  }
`

export const composeOnStart =
  (dispatch: Dispatch<SetHasThreeDStartedAction>): (() => void) =>
  () =>
    dispatch({
      type: GlobalStateAction.SET_HAS_THREE_D_STARTED_ACTION,
      data: true,
    })

const Welcome: React.FC = () => {
  const { dispatch } = useContext(GlobalStateContext)

  return (
    <Paper variants="transparent mobileFullScreen contentCenter appear">
      <WelcomeContent>
        <WelcomeTitleContainer>
          <H1 variants="withMarginBottom center">{t("welcomePage.title")}</H1>
          <H4 variants="withMarginBottom center">
            {t("welcomePage.subTitle")}
          </H4>
          <P variants="center">{t("welcomePage.titleDescription")}</P>
        </WelcomeTitleContainer>
        <Button
          id="step-in--button"
          variants="large bold"
          onClick={composeOnStart(dispatch)}
        >
          {t("welcomePage.buttonText")}
        </Button>
        <ThreeDDescription>
          <Small variants="withMarginBottom center">
            {t("welcomePage.threeDDescription1")}
          </Small>
          <Small variants="withMarginBottom center">
            {t("welcomePage.threeDDescription2")}
          </Small>
        </ThreeDDescription>
        <Logo style={{ alignSelf: "end", zIndex: 0 }} />
      </WelcomeContent>
    </Paper>
  )
}

export default Welcome
