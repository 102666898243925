import React from "react"
import styled from "styled-components"
import { SpinnerTheme, ThemeInterface } from "../../styled/interfaces"
import { getVariantStyles } from "../../styled/utils"

const SpinnerContainer = styled.div(
  (props: {
    theme: ThemeInterface
    variants?: string
    children: React.ReactNode
  }) => {
    const spinnerStyles = getVariantStyles<SpinnerTheme>(
      props.theme.spinner,
      props.variants
    )

    return `
      width: calc(${spinnerStyles.size} * 10 * var(--size-factor));
      height: calc(${spinnerStyles.size} * var(--size-factor));
      position: relative;
      filter: url(#gooey);
      z-index: 1;

      & > * {
        position: absolute;
        display: inline-block;
        left: 0;
        width: calc(${spinnerStyles.size} * var(--size-factor));
        height: calc(${spinnerStyles.size} * var(--size-factor));
        background: ${spinnerStyles.color};
        top: 25%;
        border-radius: 50%;
        animation: loading 4s infinite;
        transform: scale(0.1);
        opacity: 0;

        &:nth-child(1) {
          animation-delay: 0.5s;
        }
        &:nth-child(2) {
          animation-delay: 1s;
        }
        &:nth-child(3) {
          animation-delay: 1.5s;
        }
        &:nth-child(4) {
          animation-delay: 2s;
        }

        @keyframes loading {
          50% {
            transform: scale(1.25);
            left: 50%;
            opacity: 1;
          }
          100% {
            transform: scale(0.1);
            left: 100%;
            opacity: 0;
          }
        }
      }
    `
  }
)

const SpinnerBackground = styled.div(
  (props: {
    theme: ThemeInterface
    variants?: string
    children: React.ReactNode
  }) => {
    const spinnerStyles = getVariantStyles<SpinnerTheme>(
      props.theme.spinner,
      props.variants
    )

    return `
      transform: translateX(calc(-1rem * var(--size-factor)));
      margin-top: calc(${spinnerStyles.size} * 0.25 * var(--size-factor));
      margin-bottom: calc(${spinnerStyles.size} * 0.5 * var(--size-factor));
    `
  }
)

const SVG = styled.svg`
  opacity: 0;
  width: 0;
  height: 0;
`

interface SpinnerProps {
  variants?: string
  [x: string]: any // eslint-disable-line
}

const Spinner: React.FC<SpinnerProps> = ({ variants, ...rest }) => {
  return (
    <SpinnerBackground variants={variants} {...rest}>
      <SpinnerContainer variants={variants}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </SpinnerContainer>
      <SVG xmlns="http://www.w3.org/2000/svg" version="1.1">
        <defs>
          <filter id="gooey">
            <feGaussianBlur
              in="SourceGraphic"
              stdDeviation="10"
              result="blur"
            />
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 21 -7"
              result="goo"
            />
            <feBlend in="SourceGraphic" in2="goo" />
          </filter>
        </defs>
      </SVG>
    </SpinnerBackground>
  )
}

export default Spinner
