import React from "react"
import styled from "styled-components"
import { Bundle } from "../../../axios/instances/interfaces"
import { t } from "../../../utils"
import { H6 } from "../../common/texts"
import BundleComponent from "./BundleComponent"

const RoomMaterialsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 100%;
  overflow-y: auto;

  & > ${H6} {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
`

const BundlesFlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`

interface RoomMaterialsProps {
  bundles: Bundle[]
  hideTitles?: boolean
}

const RoomMaterials: React.FC<RoomMaterialsProps> = ({
  bundles,
  hideTitles,
}) => {
  const generalAppearanceMaterials = bundles.filter(
    bundle => bundle.showInApartmentLevel
  )
  const roomMaterials = bundles.filter(
    bundle => bundle.showInRoomLevel && !bundle.showInApartmentLevel
  )

  return (
    <RoomMaterialsContainer>
      {generalAppearanceMaterials.length ? (
        <>
          {!hideTitles && <H6>{t("theming.materialPartOfOtherRooms")}</H6>}
          <BundlesFlexContainer>
            {generalAppearanceMaterials.map(bundle => (
              <BundleComponent key={bundle.id} bundle={bundle} />
            ))}
          </BundlesFlexContainer>
        </>
      ) : null}
      {roomMaterials.length ? (
        <>
          {!hideTitles && <H6>{t("theming.roomMaterials")}</H6>}
          <BundlesFlexContainer>
            {roomMaterials.map(bundle => (
              <BundleComponent key={bundle.id} bundle={bundle} />
            ))}
          </BundlesFlexContainer>
        </>
      ) : null}
    </RoomMaterialsContainer>
  )
}

export default RoomMaterials
