import {
  GlobalState,
  GlobalStateAction,
  GlobalStateActionType,
} from "./interfaces"

const globalStateReducer = (
  globalState: GlobalState,
  action: GlobalStateActionType
): GlobalState => {
  switch (action.type) {
    case GlobalStateAction.SET_HAS_THREE_D_STARTED_ACTION:
      return {
        ...globalState,
        hasThreeDStarted: action.data,
      }
    case GlobalStateAction.SETUP:
      return {
        ...globalState,
        startParameters: action.data,
      }
    case GlobalStateAction.SET_APPLICATION_ERROR:
      return {
        ...globalState,
        applicationError: action.data,
      }
    case GlobalStateAction.SET_TENANT_SETTINGS:
      return {
        ...globalState,
        tenantSettings: action.data,
      }
    case GlobalStateAction.SET_TOKEN:
      return {
        ...globalState,
        token: action.data,
      }
    case GlobalStateAction.SET_APARTMENT_INFO:
      return { ...globalState, apartmentInfo: action.data }
    default:
      throw new Error(`Bad reducer action.`)
  }
}

export default globalStateReducer
