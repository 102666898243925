import i18n from "i18next"
import axios, { AxiosResponse } from "axios"
import {
  GetApartmentInfoParams,
  GetApartmentInfoResponse,
  GetMaterialsParams,
  GetMaterialsResponse,
  GetRoomsParams,
  GetRoomsResponse,
  GetStylesParams,
  GetStylesResponse,
  GetTenantSettingsResponse,
  LoginResponse,
  SelectMaterialParams,
  SelectMaterialResponse,
  SelectPackageParams,
  SelectPackageResponse,
  TokenExchangeResponse,
} from "./interfaces"
import { createConfig } from "../utils"

export const API_URL = process.env.REACT_APP_API_URL

const headers = { "accept-language": i18n.language || "en" }

export const apiInstance = axios.create({
  headers,
})

// TODO add interface when this is used...
export const getTenantSettings = (
  apiUrl: string,
  organisationId: string,
  tenantId: string,
  token?: string | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<AxiosResponse<GetTenantSettingsResponse>> =>
  apiInstance.get(
    `${apiUrl}/v6/${
      token ? "private" : "public"
    }/organisations/${organisationId}/tenants/${tenantId}/settings`,
    createConfig({ token, headers })
  )

export const getApartmentInfo: (
  params: GetApartmentInfoParams
) => Promise<AxiosResponse<GetApartmentInfoResponse>> = (params: GetApartmentInfoParams) =>
  apiInstance.get(
    `${params.apiUrl}/v6/public/organisations/${params.organisationId}/tenants/${params.tenantId}/projects/${params.projectId}/apartments/${params.apartmentId}`,
    createConfig({ headers })
  )

export const login = (
  apiUrl: string,
  username: string,
  password: string,
  tenantId: string
): Promise<AxiosResponse<LoginResponse>> =>
  apiInstance.post(`${apiUrl}/v1/public/accounts/login`, {
    username,
    password,
    schema: tenantId,
  })

export const tokenExchange = (
  apiUrl: string,
  company: string,
  tenantId: string,
  accessToken: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<AxiosResponse<TokenExchangeResponse>> =>
  apiInstance.get(
    `${apiUrl}/v6/public/organisations/${company}/tenants/${tenantId}/token-exchange`,
    createConfig({ token: accessToken, headers })
  )

export const getStyles = (
  params: GetStylesParams
): Promise<AxiosResponse<GetStylesResponse>> =>
  params.token ?
  apiInstance.get(
    `${params.apiUrl}/v6/private/organisations/${params.organisationId}/tenants/${params.tenantId}/projects/${params.projectId}/apartments/${params.apartmentId}/packages`,
    createConfig({ token: params.token, headers })
  ) :
  apiInstance.get(
    `${params.apiUrl}/v6/public/organisations/${params.organisationId}/tenants/${params.tenantId}/projects/${params.projectId}/apartments/${params.apartmentId}/packages`,
    createConfig({ headers })
  );

export const getMaterials = (
  params: GetMaterialsParams
): Promise<AxiosResponse<GetMaterialsResponse>> =>
  apiInstance.post(
    `${params.apiUrl}/v6/private/organisations/${params.organisationId}/tenants/${params.tenantId}/projects/${params.projectId}/apartments/${params.apartmentId}/bundles`,
    {
      roomIds: params.roomIds,
      includeMaterialOptions: params.includeMaterialOptions,
      includeApartmentBundles: params.includeApartmentBundles,
      includeRoomBundles: params.includeRoomBundles,
    },
    createConfig({ token: params.token, headers })
  )

export const getRooms: (
  params: GetRoomsParams
) => Promise<AxiosResponse<GetRoomsResponse>> = (params: GetRoomsParams) =>
  apiInstance.get(
    `${params.apiUrl}/v6/private/organisations/${params.organisationId}/tenants/${params.tenantId}/projects/${params.projectId}/apartments/${params.apartmentId}?rooms=true`,
    createConfig({ token: params.token, headers })
  )

export const selectPackage = (
  params: SelectPackageParams
): Promise<AxiosResponse<SelectPackageResponse>> =>
  apiInstance.put(
    `${params.apiUrl}/v6/private/organisations/${params.organisationId}/tenants/${params.tenantId}/projects/${params.projectId}/apartments/${params.apartmentId}/packages/${params.packageId}/select/`,
    undefined,
    createConfig({ token: params.token, headers })
  )

export const selectMaterial = (
  params: SelectMaterialParams
): Promise<AxiosResponse<SelectMaterialResponse>> =>
  apiInstance.put(
    `
${params.apiUrl}/v6/private/organisations/${params.organisationId}/tenants/${params.tenantId}/projects/${params.projectId}/apartments/${params.apartmentId}/users/null/bundles/${params.bundleId}/select/${params.materialId}
`,
    undefined,
    createConfig({ token: params.token, headers })
  )
