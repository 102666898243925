import React from "react"
import styled from "styled-components"
import { t } from "../../utils"
import { P } from "../common/texts"

const DisclaimerStyled = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  padding: calc(0.3125rem * var(--size-factor));
  background-color: rgba(255, 255, 255, 0.5);
`

const Disclaimer: React.FC = () => {
  const text = t("theModelIsIndicative")
  return text ? (
    <DisclaimerStyled>
      <P variants="small">{text}</P>
    </DisclaimerStyled>
  ) : null
}

export default Disclaimer
